import Cookies from 'universal-cookie';

import { getCustomCookie, setCustomCookie } from '@fllite-fe/api';

import { RafFormValues } from '../hooks/useAccountContext';
import { getContextForApp } from './config';

const COOKIE_NAME = 'rafFormValues';
const cookies = new Cookies();

export const saveRafFormValues = (values: RafFormValues) => {
	const { cookieDomain } = getContextForApp();
	const cookieSettings = { path: '/', maxAge: 60 * 60, domain: cookieDomain };

	setCustomCookie(COOKIE_NAME, JSON.stringify(values), cookieSettings);
};

export const clearRafFormValues = () => {
	const { cookieDomain } = getContextForApp();

	setCustomCookie(COOKIE_NAME, '', { path: '/', maxAge: 0, domain: cookieDomain });
	cookies.remove(COOKIE_NAME);
};

export const getRafFormValues: () => RafFormValues | undefined = () => {
	const values = getCustomCookie(COOKIE_NAME);
	return values;
};
