import { FC } from 'react';

import styled from '@emotion/styled';
import Link from 'next/link';

import { scrollToTarget } from '@fllite-fe/shared/src/utils/scrollToTarget';

const RequestAFlightButtonStyled = styled('a')`
	white-space: nowrap;
	border: 0 none;
	background: ${({ theme }) => theme.colors.secondary} ${({ theme }) => theme.gradients.secondary};
	border-radius: ${({ theme }) => theme.radius.buttonRadius};
	color: ${({ theme }) => theme.colors.baseFontColor};
	font-size: 14px;
	font-weight: 600;
	padding: 8px 64px;
	cursor: pointer;
`;

interface RequestAFlightButtonProps {
	href?: string;
	onClick?: (e) => void;
}

export const RequestAFlightButton: FC<RequestAFlightButtonProps> = ({ href, onClick }) => {
	const handleScrollTo = (e) => {
		e.preventDefault();
		scrollToTarget('raf');
	};

	return (
		<Link href={href || '/#raf-form'} passHref legacyBehavior>
			<RequestAFlightButtonStyled
				data-testid="button-raf-jump"
				onClick={onClick ? onClick : !href ? handleScrollTo : null}
			>
				Request a flight
			</RequestAFlightButtonStyled>
		</Link>
	);
};
