import { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import Link from 'next/link';

import { SelectScenarioOptions } from '../SelectScenarioOptions';

interface ItemProps {
	id: string;
	question: string;
	answer: string | ReactNode;
	public?: boolean;
	dataTestId: string;
}

export interface SectionProps {
	id: string;
	title: string;
	illustrationImgUrl: string;
	items: ItemProps[];
	anchor: string;
	dataTestId?: string;
}

const bookingMyTicketItems: ItemProps[] = [
	{
		id: 'bookingMyTicketItemsQuestion0',
		question: 'How can I book my next flight?',
		answer: (
			<>
				Fill out the <Link href="/">Request a Flight form</Link> and if we have availability with
				our managed aircraft you will see realtime pricing ready to book! If any of those options
				are not right for you, you can simply Request More Options right from your Trip Page and we
				will get you more.
				<Box sx={{ padding: { xs: '24px 0', md: '24px 64px' } }}>
					<SelectScenarioOptions />
				</Box>
			</>
		),
		public: true,
		dataTestId: 'bookingMyTicketItemsQuestion0',
	},
	{
		id: 'bookingMyTicketItemsQuestion1',
		question: 'How much does a one-hour flight cost on a private jet? (If applicable)',
		answer:
			'It really varies depending on the size of the aircraft. From a Very Light Jet it can be as low as $1500 however entering into larger aircraft it can be $5,000+ per hour.',
		dataTestId: 'bookingMyTicketItemsQuestion1',
	},
	{
		id: 'bookingMyTicketItemsQuestion2',
		question: 'What methods of payment are accepted?',
		answer: 'At the moment we can accept wire only. More on the way!',
		dataTestId: 'bookingMyTicketItemsQuestion2',
		public: true,
	},
	{
		id: 'bookingMyTicketItemsQuestion3',
		question: 'What documents do I need to send FLLITE to confirm my booking?',
		answer:
			"It all depends on the operator that you're flying with and if you're traveling internationally. However, the base information we need is a signed contract and you can bring your id at the time of boarding.",
		dataTestId: 'bookingMyTicketItemsQuestion3',
	},
	{
		id: 'bookingMyTicketItemsQuestion4',
		question: 'Are flights taxed?',
		answer: (
			<>
				<Typography mb={1}>Yes, flights are subject to FET and Segment tax. </Typography>
				<Typography mb={1}>
					FET is 7.5% of the flight price and we will calculate it for you during checkout.
				</Typography>
				<Typography>Segment tax is $4.30 per passenger per flight segment.</Typography>
			</>
		),
		dataTestId: 'bookingMyTicketItemsQuestion4',
		public: true,
	},
];
const changingMyBookingItems: ItemProps[] = [
	{
		id: 'changingMyBookingItemsQuestion0',
		question: 'Does FLLITE provide any flexibility around amending any confirmed bookings?',
		answer:
			'With flying privately any changes can happen but it will all depend on the aircraft and crew availability. If a change is needed, we will do our best to accommodate that change.',
		dataTestId: 'changingMyBookingItemsQuestion0',
		public: true,
	},
	{
		id: 'changingMyBookingItemsQuestion1',
		question: 'When do I receive the flight brief/itinerary?',
		answer:
			'As you go through the process of booking a trip with Fllite, your trip page is your itinerary and will help you through the flow of booking. You can login and review any/all data concerning your trip as it populates.',
		dataTestId: 'changingMyBookingItemsQuestion1',
	},
	{
		id: 'changingMyBookingItemsQuestion2',
		question: 'How long before take-off do I have to be at the airport?',
		answer: 'Typically you can arrive 15 minutes before your scheduled departure.',
		dataTestId: 'changingMyBookingItemsQuestion2',
		public: true,
	},
	{
		id: 'changingMyBookingItemsQuestion3',
		question: 'How much luggage is allowed on board?',
		answer:
			'Luggage amount and weight is all based on the aircraft you fly. But typically a rule of thumb is one piece of luggage and a carryon per person.',
		dataTestId: 'changingMyBookingItemsQuestion3',
	},
	{
		id: 'changingMyBookingItemsQuestion4',
		question: 'What is the cancellation policy?',
		answer:
			'Cancellation policy will be outlined with the aircraft you fly. Every operator has their own policy which we encourage that you review.',
		dataTestId: 'changingMyBookingItemsQuestion4',
		public: true,
	},
];

const supportItems: ItemProps[] = [
	{
		id: 'supportItemsQuestion0',
		question: 'Is it possible to book a pickup on arrival?',
		answer: 'Yes, just let us know by filing out the Special Request form on your trip page.',
		dataTestId: 'supportItemsQuestion0',
	},
	{
		id: 'supportItemsQuestion1',
		question: 'Does FLLITE provide any concierge service?',
		answer:
			'Yes, you have a dedicated Fllite Crew member that will be with you on your travel days. ',
		dataTestId: 'supportItemsQuestion1',
	},
	{
		id: 'supportItemsQuestion2',
		question: 'What time of day can I travel? What is the earliest and latest time of travel?',
		answer:
			"With flying privately, it's completely up to you! The only issue is that some airports might have noise abatement policies that will restrict flights certain times during the day.",
		public: true,
		dataTestId: 'supportItemsQuestion2',
	},
	{
		id: 'supportItemsQuestion3',
		question: 'Which languages do the Customer Care team speak?',
		answer: 'As of now, English',
		dataTestId: 'supportItemsQuestion3',
		public: true,
	},
	{
		id: 'supportItemsQuestion4',
		question: 'Could I bring a bottle of wine on board?',
		answer: 'Yes, you can bring a bottle of wine on board.',
		dataTestId: 'supportItemsQuestion4',
		public: true,
	},
	{
		id: 'supportItemsQuestion5',
		question: 'Are pets allowed onboard the cabin?',
		answer: 'This is a policy of the operator but most of the time, yes you can bring pets.',
		dataTestId: 'supportItemsQuestion5',
		public: true,
	},
];

export const items: SectionProps[] = [
	{
		id: 'booking-my-ticket',
		illustrationImgUrl: '/assets/faq-booking.webp',
		title: 'Booking my ticket',
		items: bookingMyTicketItems,
		anchor: 'booking-my-ticket',
	},
	{
		id: 'changing-my-booking',
		illustrationImgUrl: '/assets/faq-changing.webp',
		title: 'Changing my booking',
		items: changingMyBookingItems,
		anchor: 'changing-my-booking',
	},
	{
		id: 'support',
		illustrationImgUrl: '/assets/faq-support.webp',
		title: 'Support',
		items: supportItems,
		anchor: 'support',
	},
];
