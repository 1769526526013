import { TripTripCreatePaymentType } from '@fllite-fe/api';

export const tripVariants = [
	{
		value: TripTripCreatePaymentType.instant_payment,
		label: 'Book a trip',
		cons: [],
		pros: ['Flight guaranteed once paid', 'Enjoy the comfort of flying privately'],
		image: '/assets/trip_variant_private.webp',
		tag: { label: 'perfect for families', variant: 'primaryBold' },
		minDaysBefore: null,
		disabled: false,
	},
	{
		value: TripTripCreatePaymentType.standard_payment,
		label: 'Propose a trip',
		cons: ['Flight not guaranteed to take off'],
		pros: [
			'Share empty seats with the Fllite community',
			'Split the price when others join the trip',
		],
		image: '/assets/trip_variant_propose.webp',
		tag: { label: 'New!', variant: 'successBold' },
		minDaysBefore: 10,
	},
];
