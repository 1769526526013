import { FC } from 'react';

import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Paper,
	Typography,
} from '@mui/material';

import { SectionProps } from './items';

interface SectionAccordionProps {
	section: SectionProps;
	publicPage?: boolean;
}

export const SectionAccordion: FC<SectionAccordionProps> = ({ section, publicPage }) => {
	const theme = useTheme();

	return (
		<Box sx={{ padding: '20px 0 80px 0', textAlign: 'left' }}>
			{!publicPage && (
				<Typography
					variant="h3"
					sx={{
						fontSize: '24px',
						fontWeight: 600,
						margin: '24px 0',
						color: theme.colors.primaryDarkest,
					}}
					id={section.anchor}
				>
					{section.title}
				</Typography>
			)}

			<Paper sx={{ borderRadius: '8px', border: '1px solid #DDDFE3', boxShadow: 'none' }}>
				{section.items
					.filter((item) => (publicPage ? item.public : true))
					.map((item) => (
						<Accordion
							key={item.id}
							data-testid={item.dataTestId}
							sx={{
								'&:first-of-type': {
									borderTopLeftRadius: '8px',
									borderTopRightRadius: '8px',
								},
								'&:last-of-type': {
									borderBottomLeftRadius: '8px',
									borderBottomRightRadius: '8px',
									boxShadow: 'none',
								},
							}}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								data-testid={`${item.dataTestId}-summary`}
							>
								{item.question}
							</AccordionSummary>
							<AccordionDetails data-testid={`${item.dataTestId}-details`}>
								{item.answer}
							</AccordionDetails>
						</Accordion>
					))}
			</Paper>
		</Box>
	);
};
