import { FC } from 'react';

import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { animated } from 'react-spring';

interface CircleProps {
	className?: string;
	style?: any;
}

const cloudAnimation = keyframes`
	0%  {
		transform: translate3d(0, 0, 0) rotate(0);
		}

	50%  {
			transform: ${`translate3d(${Math.floor(Math.random() * 13) + 15}px, ${
				Math.floor(Math.random() * 9) + 12
			}px, 0) rotate(-${Math.random() * 4}deg)`};
		}
		

  100% {
		transform: translate3d(0, 0, 0) rotate(0);
		}
`;

const Image = styled(animated.img)`
	pointer-events: none;
	animation: ${() => css`
		${cloudAnimation} ${`${Math.floor(Math.random() * 7) + 8}s`} ease-in-out infinite
	`};
`;

export const Cloud: FC<CircleProps> = ({ className, style }) => (
	<Image src="/assets/cloud.webp" alt="cloud" className={className} style={style} />
);
