import { FC } from 'react';

import styled from '@emotion/styled';
import { CheckCircleOutlineRounded, DoNotDisturbAltRounded } from '@mui/icons-material';
import {
	Box,
	Chip,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	useMediaQuery,
} from '@mui/material';
import Image from 'next/image';

import { TripTripCreatePaymentType } from '@fllite-fe/api';
import { diff } from '@fllite-fe/shared/src/utils/datetime';

import { tripVariants } from '../const/tripVariants';
import { RealTimePricingProposalExtended } from '../hooks/useRealTimePricingProposals';
import { muiTheme } from '../muiTheme';
import { Proposal } from '../types';
import { Button } from './Button';
import { Text } from './Text';

interface SelectScenarioProps {
	selectedScenario?: TripTripCreatePaymentType;
	onConfirm?: () => void;
	handleSelectScenario?: (any) => void;
	selectedProposal?: Proposal | RealTimePricingProposalExtended;
}

const Option = styled('div')<{ active?: boolean; disabled?: boolean }>`
	border: 1px solid
		${({ theme, active }) => (active ? theme.colors.primary : theme.colors.grayLight)};
	border-radius: 8px;
	transition: border ${({ theme }) => theme.transition.baseTransition};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
	filter: ${({ disabled }) => disabled && 'grayscale(1)'};
	opacity: ${({ disabled }) => disabled && '0.5'};
	width: 100%;
	flex-shrink: 0;
	background: ${({ active }) => (active ? 'rgba(140, 192, 255, 0.1)' : 'white')};
	position: relative;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${({ theme }) => theme.breakpoints.up('md')} {
		width: ${100 / 3}%;
	}
`;

const VariantImage = styled(Image)`
	aspect-ratio: 330 / 126;
	width: unset !important;
	height: unset !important;
	min-width: 100%;
	min-height: 100%;
`;

export const SelectScenarioOptions: FC<SelectScenarioProps> = ({
	selectedScenario,
	handleSelectScenario,
	selectedProposal,
}) => {
	const dateFrom = selectedProposal?.proposalLegs[0].departureAt;
	const daysLeft: number = diff(dateFrom, new Date(), 'day');

	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));

	return (
		<Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
			{tripVariants.map((variant) => {
				const active = selectedScenario === variant.value;
				const expired = daysLeft && variant.minDaysBefore && daysLeft < variant.minDaysBefore;
				const isDisabled = expired || variant.disabled;

				return (
					<Option
						onClick={() => {
							if (isDisabled) {
								return null;
							}
							handleSelectScenario?.(variant);
						}}
						active={active}
						disabled={isDisabled}
						key={variant.value}
					>
						{variant.tag && (
							<Chip
								variant={variant.tag.variant as 'primaryBold' | 'secondaryBold' | 'successBold'}
								label={variant.tag.label}
								sx={{
									position: 'absolute',
									top: '0',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									zIndex: 3,
								}}
							/>
						)}
						<Box>
							<Box height="120px" position="relative" margin="5px 5px 16px" overflow="hidden">
								<VariantImage
									src={variant.image}
									alt={variant.label}
									{...(isDesktop ? { width: 330, height: 126 } : { fill: true })}
								/>
							</Box>

							<Box textAlign="center" mb="16px">
								<Text bold gray>
									{variant.label}
								</Text>
							</Box>
							<Box>
								<List>
									{variant.cons?.map((item, i) => (
										<ListItem
											sx={{
												marginBottom: 0,
												padding: '8px 8px 8px 24px',
												background:
													i === 0 &&
													'linear-gradient(0deg, rgba(255, 47, 0, 0.1), rgba(255, 47, 0, 0.1)), #FFFFFF',
											}}
											key={item}
										>
											<ListItemIcon>
												<DoNotDisturbAltRounded
													color="error"
													sx={{ fontSize: '16px', marginTop: '2px' }}
												/>
											</ListItemIcon>
											<ListItemText primary={item} sx={{ fontSize: '14px', fontWeight: 500 }} />
										</ListItem>
									))}

									{variant.pros?.map((item, i) => (
										<ListItem
											sx={{
												marginBottom: 0,
												padding: '8px 8px 8px 24px',
												background:
													variant.cons.length === 0 &&
													i === 0 &&
													'linear-gradient(0deg, rgba(83, 174, 14, 0.1), rgba(83, 174, 14, 0.1)), #FFFFFF',
											}}
											key={item}
										>
											<ListItemIcon>
												<CheckCircleOutlineRounded
													color="success"
													sx={{ fontSize: '16px', marginTop: '2px' }}
												/>
											</ListItemIcon>
											<ListItemText primary={item} sx={{ fontSize: '14px', fontWeight: 500 }} />
										</ListItem>
									))}
								</List>
							</Box>
						</Box>
						{handleSelectScenario && (
							<Box textAlign="center" mt="24px">
								<Button outlinePrimary={!active} primary={active}>
									Select this option
								</Button>
							</Box>
						)}
					</Option>
				);
			})}
		</Stack>
	);
};
