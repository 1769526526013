import { FC } from 'react';

import styled from '@emotion/styled';

import { Circle } from './Circle';
import { Icon, IconKeys } from './Icon';

interface PulseIconProps {
	icon: IconKeys;
	size: number;
}

const CircleContainer = styled('div')`
	position: relative;
	width: 100px;
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
`;

const StyledCircle = styled(Circle)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	pointer-events: none;
`;

const BenefitIcon = styled(Icon)<{ size: number }>`
	& > svg {
		height: ${({ size }) => `${size}px`};
		width: ${({ size }) => `${size}px`};
	}
`;

const PulseIcon: FC<PulseIconProps> = ({ icon, size }) => (
	<CircleContainer>
		<BenefitIcon type={icon} {...{ size }} />
		<StyledCircle radius={60} pulsing />
	</CircleContainer>
);

export default PulseIcon;
