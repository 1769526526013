import styled from '@emotion/styled';

import { ClaimItem } from './ClaimItem';

const ClaimList = styled('div')`
	margin: auto;
	margin-bottom: 80px;
	max-width: ${({ theme }) => theme.dimensions.pageWidth};

	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
		justify-content: space-between;
	}
`;

export const Claim = () => (
	<ClaimList data-testid="footer-claim-list">
		<ClaimItem
			title="1,000"
			subTitle="Operators"
			description="Fllite has access to thousands of aircraft, ready and waiting for you."
		/>

		<ClaimItem
			title="7,000+"
			subTitle="Airports"
			description="Commercial air travel only serves about 300 airports. Fllite gives you access to many more."
		/>

		<ClaimItem
			title="NBAA"
			subTitle="Member"
			description="Fllite is a member of the NBAA, which supports the entire private aviation community."
		/>
	</ClaimList>
);
