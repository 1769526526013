import { FC, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { items } from '@fllite-fe/shared/src/components/FAQ/items';
import { SectionAccordion } from '@fllite-fe/shared/src/components/FAQ/SectionAccordion';
import { SectionTabs } from '@fllite-fe/shared/src/components/FAQ/SectionTabs';

interface TitleProps {
	onLightBackground?: boolean;
}
const Title = styled('h2')<TitleProps>`
	color: ${({ theme, onLightBackground }) =>
		onLightBackground ? theme.colors.primaryDarkest : theme.colors.baseFontColor};
	font-size: 24px;
	text-align: left;
	${({ theme }) => theme.breakpoints.up('md')} {
		text-align: center;
		margin-bottom: 36px;
		font-size: 36px;
	}
`;

const Container = styled('div')`
	text-align: center;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: 100px;
	}
`;

interface FAQProps {
	onLightBackground?: boolean;
}

export const FAQ: FC<FAQProps> = ({ onLightBackground }) => {
	const faqRef = useRef(null);
	const router = useRouter();

	useEffect(() => {
		let timer;

		if (router.asPath.includes('#faq')) {
			timer = setTimeout(
				() => faqRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
				500,
			);
		}

		return () => {
			clearTimeout(timer);
		};
	}, []);

	const [selectedSection, setSelectedSection] = useState(items[0].id);

	const handleSelectSection = (sectionId: string) => {
		setSelectedSection(sectionId);
	};

	const section = items.find((item) => item.id === selectedSection);

	return (
		<Container ref={faqRef}>
			<Title data-testid="h2-faq" onLightBackground={onLightBackground}>
				Frequently asked questions
			</Title>
			<SectionTabs
				handleSelectSection={handleSelectSection}
				selectedSection={selectedSection}
				onLightBackground={onLightBackground}
			/>
			<SectionAccordion section={section} publicPage />
		</Container>
	);
};
