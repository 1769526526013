import { FC } from 'react';

import styled from '@emotion/styled';
import { Tab, Tabs } from '@mui/material';

import { items } from './items';

interface Props {
	handleSelectSection: (sectionId: string) => void;
	selectedSection: string;
	onLightBackground?: boolean;
}

const TabsWrapper = styled(Tabs)`
	&.MuiTabs-root {
		.MuiTabs-flexContainer {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: stretch;
			${({ theme }) => theme.breakpoints.up('md')} {
				align-items: center;
			}

			button {
				font-size: 16px;
			}
		}
		.MuiTabs-indicator {
			display: none;

			${({ theme }) => theme.breakpoints.up('md')} {
				display: block;
			}
		}
	}
`;

interface TabItemProps {
	onLightBackground?: boolean;
}

const CustomTabsWrapper = styled(TabsWrapper)`
	&.MuiTabs-root .MuiTabs-flexContainer {
		gap: 0 8px;
	}
`;

const TabItem = styled(Tab)<TabItemProps>`
	&.MuiTab-root {
		color: ${({ theme, onLightBackground }) =>
			onLightBackground ? theme.colors.primaryDarkest : '#fff'};
		font-size: 20px;
		font-weight: 500;
		text-transform: none;
		font-weight: normal;
		border-bottom: 3px solid transparent;
		background-color: ${({ onLightBackground }) => (onLightBackground ? '#fff' : 'transparent')};

		${({ theme }) => theme.breakpoints.up('md')} {
			font-size: 16px;
			border-bottom: none;
		}
	}

	&.Mui-selected {
		font-weight: 700;
		border-color: #ff9200;

		${({ theme }) => theme.breakpoints.up('md')} {
			border-bottom: transparent;
		}
	}
`;

export const SectionTabs: FC<Props> = ({
	handleSelectSection,
	selectedSection,
	onLightBackground,
}) => (
	<CustomTabsWrapper
		value={selectedSection}
		onChange={(event, value) => handleSelectSection(value)}
		aria-label="faq"
		TabIndicatorProps={{ sx: { background: '#FF9200' } }}
		variant="fullWidth"
	>
		{items.map((item) => (
			<TabItem
				onLightBackground={onLightBackground}
				label={item.title}
				value={item.id}
				id={item.id}
				key={item.id}
				data-testid={item.title}
			/>
		))}
	</CustomTabsWrapper>
);
